import React from 'react';
import { Link } from 'react-router-dom';
import './ApplicationSuccess.css';
import Header from './Header';
import Footer from './Footer';

const ApplicationSuccess = () => {
  return (
    <div className="application-success">
      <Header />
      <div className="success-message-container">
        <h1>Application Submitted Successfully!</h1>
        <p>Thank you for your application. We will review it and get in touch with you within 24 hours.</p>
        <Link to="/" className="back-to-home">Back to Home</Link>
      </div>
      <Footer />
    </div>
  );
};

export default ApplicationSuccess;
