import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Pages.css';
import './PostRegistrationPost.css'
import Header from './Header';
import Footer from './Footer';

const PostRegistrationPage = () => {
  const [firstName, setFirstName] = useState('');
  const { trainerId } = useParams(); // Assuming the trainer ID is passed as a URL parameter
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the trainer's details using the trainer ID
    const fetchTrainer = async () => {
      try {
        const response = await axios.get(`https://server.findmytrainer.co.zw/api/trainers/${trainerId}`);
        setFirstName(response.data.first_name);
      } catch (error) {
        console.error('Error fetching trainer details:', error);
      }
    };

    fetchTrainer();
  }, [trainerId]);

  const handleCreateApplicationClick = () => {
    navigate('/');
  };

  return (
    <div>
      {/* Navbar */}
      <Header/>

      {/* Page Title Section */}
      <div className="page-title-section">
        <div className="page-header w-container">
          <div className="multiple-linked-text-wrapper">
            <h1 className="page-title">Welcome, </h1>
            <h1 className="page-title">{firstName || 'Trainer'}</h1> {/* Show trainer's first name */}
          </div>
          <h2 className="page-subtitle">Fill in our quick and easy application form to build your profile!</h2>
        </div>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="content-section text w-container">
          <div className="trainer-welcome-content-wrapper">
            <div className="content-text">
              Your application has been submitted and is now waiting for approval.
              <br /><br />
              To verify your email address and for a photo to show on your profile, it is mandatory that you send 3 pictures of yourself using the email address that you used for registration.
              <br />‍<br />
              <strong>1. Use a professional photo, this builds credibility and makes a strong impression on clients.</strong>
              <br /><br />
              <strong>2. We will get back to you for verification and approval but keep an eye on your spam mail and add support@findmytrainer.co.zw to your contact list</strong>
              <br />‍<br/>
              Thank you for Registering with Find My Trainer.
              <br />
            </div>
            <div className="welcome-content-block">
              <button className="btn_reg" onClick={handleCreateApplicationClick}>
                Return to Home
              </button>
            </div>
            <div className="content-text">
              <strong>NOTE: If you get disconnected - log in and click the 'Update Profile' button to finish your application.</strong>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer/>
    </div>
  );
};

export default PostRegistrationPage;
