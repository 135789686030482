import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TrainerDashboard = () => {
  const [name, setName] = useState('');

  useEffect(() => {
    const fetchTrainerName = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/api/trainers/me', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setName(response.data.name);
        } catch (error) {
          console.error('Error fetching trainer name:', error);
        }
      }
    };

    fetchTrainerName();
  }, []);

  return (
    <div>
      <h1>Welcome, {name}</h1>
    </div>
  );
};

export default TrainerDashboard;
