import React, { useState } from 'react';
import './Header.css'; // Include your CSS file

const Header = () => {
  // Use state to handle the menu visibility
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the menu when the hamburger icon is clicked
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="menu-wrapper">
      <div className="container1 clearfix et_menu_container">
        <div className="logo_container">
          <span className="logo_helper"></span>
          <a href="https://findmytrainer.co.zw/">
            <img 
              src="logo2.png" 
              width="343" 
              height="343" 
              alt="Find My Trainer" 
              id="logo" 
              data-height-percentage="54" 
              data-actual-width="343" 
              data-actual-height="343"
            />          </a>
          </div>
          <div id="et-top-navigation" data-height="66" data-fixed-height="40">
            <button className="hamburger" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </button>
            <nav id="top-menu-nav" className={menuOpen ? 'active' : ''}>
              <ul id="top-menu" className="nav">
                <li className="menu-item">
                  <a href="/">Home</a>
                </li>
                <li className="menu-item">
                  <a href="/browse">Browse Trainers</a>
                </li>
                <li className="menu-item">
                  <a href="/trainer-register">Register As Trainer</a>
                </li>
                <li className="menu-item">
                  <a href="/about">About</a>
                </li>
                <li className="menu-item final">
                  <a href="https://findmytrainer.co.zw/blog/">Blog</a>
                </li>
              </ul>
            </nav>
            <div id="et_top_search">
              <span id="et_search_icon"></span>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default Header;
