import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateApplicationForm.css';
import Header from './Header';
import Footer from './Footer';

const TrainerSignUpForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    password: '',
    hearAbout: '',
    sex: '',
    dateOfBirth: '',
    location: '',
    gymAddress: '',
    experienceYears: '',
    chargePerSession: '',
    chargePerBlock: '',
    sessionsPerBlock: '',
    onlineCoaching: '',
    expertise: [], // Added expertise as an array
    instagramHandle: '',
    about: ''
  });

  const [statusMessage, setStatusMessage] = useState('');
  const navigate = useNavigate();

  const experienceOptions = [
    'Less than 1 year',
    '1-2 years',
    '3-5 years',
    '6-10 years',
    'More than 10 years'
  ];

  const expertiseOptions = [
    'Weight Training',
    'Cardio',
    'Yoga',
    'Pilates',
    'Nutrition',
    'Strength Training',
    'High-Intensity Interval Training (HIIT)',
    'Flexibility Training',
    // Add more options as needed
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      if (checked) {
        setFormData((prev) => ({
          ...prev,
          expertise: [...prev.expertise, value] // Add selected expertise
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          expertise: prev.expertise.filter((item) => item !== value) // Remove unselected expertise
        }));
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://server.findmytrainer.co.zw/api/trainers/register", formData);
      setStatusMessage("Registration successful! Your account is pending approval.");
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        password: '',
        hearAbout: '',
        sex: '',
        dateOfBirth: '',
        location: '',
        gymAddress: '',
        experienceYears: '',
        chargePerSession: '',
        chargePerBlock: '',
        sessionsPerBlock: '',
        onlineCoaching: '',
        expertise: [],
        instagramHandle: '',
        about: ''
      });
      navigate(`/post-registration/${response.data.trainerId}`);
    } catch (error) {
      console.error("Error creating trainer account:", error);
      setStatusMessage("There was an error with your registration.");
    }
  };

  return (
    <div className="create-application-form">

      <Header/>

    <div className="trainer-signup-page">
      <h2>Trainer Sign Up</h2>
      <form onSubmit={handleFormSubmit}>
        {/* Existing form fields */}
        <div className="form-group">
          <label htmlFor="firstName">First Name *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="contactNumber">What is your contact number? *</label>
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="hearAbout">How did you hear about Find My Trainer? *</label>
          <input
            type="text"
            id="hearAbout"
            name="hearAbout"
            value={formData.hearAbout}
            onChange={handleChange}
            placeholder="e.g., Instagram, Google, Friend"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sex">Select your sex. *</label>
          <select id="sex" name="sex" value={formData.sex} onChange={handleChange} required>
            <option value="">Select an option</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">What is your date of birth? *</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            placeholder="dd/mm/yyyy"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="location">Where are you based? *</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Enter your location"
            required
          />
          <small className="form-note">
            This will allow clients to filter by location.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="gymAddress">What is the address of the gym where you're based? *</label>
          <input
            type="text"
            id="gymAddress"
            name="gymAddress"
            value={formData.gymAddress}
            onChange={handleChange}
            placeholder="Enter the gym address"
            required
          />
          <small className="form-note">
            Please use the single-line address as it appears on Google. If you do not work from a gym, please enter your city.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="experienceYears">How long have you been a personal trainer? *</label>
          <select id="experienceYears" name="experienceYears" value={formData.experienceYears} onChange={handleChange} required>
            <option value="">Select an option</option>
            {experienceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
        <label htmlFor="chargePerSession">How much do you charge per session? *</label>
        <input
          type="text"
          id="chargePerSession"
          name="chargePerSession"
          value={formData.chargePerSession}
          onChange={handleChange}
          placeholder="Enter the rate per session"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="chargePerBlock">How much do you charge for a block of sessions? *</label>
        <input
          type="text"
          id="chargePerBlock"
          name="chargePerBlock"
          value={formData.chargePerBlock}
          onChange={handleChange}
          placeholder="Enter the rate for a block of sessions"
          required
        />
        <small className="form-note">If you offer multiple options, enter the price of your most basic package and use the 'About' field to detail others.</small>
      </div>

      <div className="form-group">
        <label htmlFor="sessionsPerBlock">How many sessions are included in a block? *</label>
        <input
          type="text"
          id="sessionsPerBlock"
          name="sessionsPerBlock"
          value={formData.sessionsPerBlock}
          onChange={handleChange}
          placeholder="Enter the number of sessions"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="onlineCoaching">Do you offer online coaching? *</label>
        <select id="onlineCoaching" name="onlineCoaching" value={formData.onlineCoaching} onChange={handleChange} required>
          <option value="">Select an option</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>

        {/* New Expertise Section */}
        <div className="form-group">
          <label>What are your areas of expertise? *</label>
          <div className="checkbox-group">
            {expertiseOptions.map((option, index) => (
              <div key={index} className="checkbox-item">
                <input
                  type="checkbox"
                  id={`expertise-${index}`}
                  name="expertise"
                  value={option}
                  checked={formData.expertise.includes(option)}
                  onChange={handleChange}
                />
                <label htmlFor={`expertise-${index}`}>{option}</label>
              </div>
            ))}
          </div>
        </div>

        {/* Instagram Handle Section */}
        <div className="form-group">
          <label htmlFor="instagramHandle">What is your Instagram handle?</label>
          <input
            type="text"
            id="instagramHandle"
            name="instagramHandle"
            value={formData.instagramHandle}
            onChange={handleChange}
            placeholder="@findmytrainer"
          />
          <small className="form-note">Please include '@' at the beginning. For example, @findmytrainer</small>
        </div>

        {/* About Section */}
        <div className="form-group">
          <label htmlFor="about">About *</label>
          <textarea
            id="about"
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder="Enter details about your experience, qualifications, training style, areas of expertise, availability, and any specialisations or niche areas."
            required
          />
          <small className="form-note">
            Who are you and why did you become a PT? Encourage the customer to take action - 'Send me an enquiry to have a chat!'. Use headings, quotes, and bullet points to draw the customer's attention. P.S. This can be updated at a later date.
          </small>
        </div>

        <button type="submit">Sign Up</button>
        {statusMessage && <div className="mt-3">{statusMessage}</div>}
      </form>
    </div>
      <Footer/>
    </div>
  );
};

export default TrainerSignUpForm;
